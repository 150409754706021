<template lang="pug">
    .main-wrapper.paciente-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Guia Eletrônica</b>

        Dialog.dialogExecutar(header='Validar guias' :modal='true' :visible.sync='dialogExecutar')
            .ta-center
                p As guias estão sendo processadas no estabelecimento <b>{{ currentEstabelecimento.nm_fantasia }}</b>. <br /><b>Data da validação</b> {{ model.dt_execucao_f }}<br />. Deseja prosseguir?
                .ta-center.mb-4(v-if='waitingExecutar')
                    ProgressSpinner.waitingExecutar-spinner(strokeWidth='6')
                Button.mb-4.p-button-success(v-else label='Validar guias' icon='jam jam-ticket' @click='executarGuias()')

        form(@submit.prevent='handleSubmit()')
            .ta-center.my-4(v-if='waitingForm')
                ProgressSpinner.waitingForm-spinner(strokeWidth='2')
            div(v-else)
                
                Panel.mb-2(header='Validar guia')
                    .p-grid.p-align-left(v-if='results.length')
                        .p-col-12
                            label.form-label Data da execução:
                            .p-inputgroup
                                Calendar(v-model='model.dt_execucao' :maxDate="this.max_date" dateFormat="dd/mm/yy" :locale="ptbr"
                                    :manualInput='true' :touchUI='windowInnerWidth < 576' )
                                //- Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_execucao = null;')
                    div(v-if='results.length')
                        div(v-for='guias_paciente in guias')
                            .mb-4(v-for='guia, estabelecimento  in guias_paciente')
                                div Paciente:
                                span(style="float:right;margin:0") Estabelecimento:
                                    h3(style="margin:0;display:inline-block") {{estabelecimento}}
                                h2(style="float:left;margin:0") {{guia.cd_paciente.nm_pessoa_fisica}}
                                DataTable(dataKey="cd_guia"
                                    :value='results.filter(result => result.cd_estabelecimento == guia.cd_estabelecimento.id && result.cd_paciente == guia.cd_paciente.id)')
                                    Column(headerStyle='width: 5%;' bodyStyle='text-align:center')
                                        template(#body='props')
                                            span(v-if='props.data.ie_estorno_pendente' v-tooltip.top="`Aguardando Estorno`") <i class='jam jam-close-rectangle'></i>
                                            Checkbox(v-else-if='props.data.cd_guia_situacao == 4' v-model="selected"
                                                :value='props.data.cd_guia' name='guias_select' @input="onChangeSelected")
                                            span(v-else) <i class='jam jam-check'></i>
                                    Column(headerStyle='width: 5%;' field='cd_guia_procedimentos' header='Cod.')
                                    Column(headerStyle='width: 60%;' header='Procedimento')
                                        template(#body="props")
                                            span {{ props.data.nm_procedimento }}
                                            span.d-block.o-hidden(v-if="props.data.nm_usuario_execucao" style="font-size:11px")
                                                | Usuário execução: {{ props.data.nm_usuario_execucao }} ({{ props.data.dt_execucao_f }})
                                    Column(headerStyle='width: 15%;' header='Pedidos de exame')
                                        template(#body='props')
                                            span(v-if='props.data.anexos != undefined && ! props.data.anexos.length') Não possui
                                            ul(v-else style='margin:0;padding:0;list-style-type:none')
                                                li(v-for='anexo, idx in props.data.anexos' :key='idx')
                                                    a(:href='anexo.aq_anexo' target='_blank') <i class='jam jam-attachment' style='font-size:12px'></i> Anexo {{ idx + 1 }}
                                    Column(headerStyle='width: 10%;' field='nr_valortotal_f' bodyStyle='text-align:center' header='Valor')
                                    //- Column(v-if"1>0" headerStyle="width: 10%;" bodyStyle="text-align: center" header="Tem O.S")
                                    Column(
                                        v-if="guia.cd_estabelecimento.cd_integracao_shift" 
                                        headerStyle="width: 10%;" 
                                        bodyStyle="text-align: center" 
                                        header="Foi importado?"
                                    )
                                        template(#body="props")
                                            span(v-if="props.data.cd_ordem_servico_shift") <i class='jam jam-check'></i>
                                            span(v-else) <i class='jam jam-close'></i> 
                                                <br/>
                                                    Checkbox(
                                                        v-model="selectedReenviar"
                                                        name='guias_select' 
                                                        :value="props.data.cd_guia"
                                                    )
                                    Column(headerStyle='width: 5%;' bodyStyle='text-align:center')
                                        template(#body='props')
                                            ProgressSpinner.waitingImprimirGuia(v-if='waitingImprimirGuia == props.data.cd_horario_agendamento' strokeWidth='6')
                                            Button.p-button-raised.p-button-rounded(v-else-if='props.data.cd_horario_agendamento'
                                                icon='jam jam-printer' type='button' @click='imprimirGuia(props.data.cd_horario_agendamento)')

                        .ta-center.mb-4(v-if='waitinAction')
                            ProgressSpinner.waitinAction( strokeWidth='4')
                        .ta-center.mb-4(v-else)
                            Button.mr-2(label='Voltar' icon='jam jam-log-out' type='button' @click='reset()')
                            Button.mr-2(label='Imprimir guias' icon='jam jam-printer' type='button' @click='imprimirTodasGuias()')
                            Button.mr-2.p-button-success(label='Validar Guias' icon='jam jam-ticket' type='button' @click='openDialogExecutar()')
                            Button.p-button-success(
                                v-if="ie_tem_integracao_shift" 
                                label='Reenviar exames' 
                                icon='jam jam-repeat' 
                                type="button" 
                                @click='reenviarPedidos()'
                                :disabled="selectedReenviar.length > 0 ? false: true"
                            )
                    .p-grid.p-fluid.p-justify-center(v-else)
                        .p-col-12.p-md-2
                            .p-grid.p-fluid.p-align-end

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.id.$error }")
                                    label.form-label Guia:
                                    InputText(type='text' v-model='$v.model.id.$model')
                                    .feedback--errors(v-if='submitted && $v.model.id.$error')
                                        .form-message--error(v-if="!$v.model.id.required") <b>Código da guia</b> é obrigatório.

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.hash.$error }")
                                    label.form-label Senha:
                                    InputText(type='text' v-model='$v.model.hash.$model')
                                    .feedback--errors(v-if='submitted && $v.model.hash.$error')
                                        .form-message--error(v-if="!$v.model.hash.required") <b>Senha</b> é obrigatório.


                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.dt_execucao.$error }")
                                    label.form-label Data da execução:
                                    .p-inputgroup
                                        Calendar( v-model='$v.model.dt_execucao.$model' :maxDate="this.max_date" dateFormat="dd/mm/yy" :locale="ptbr"
                                            :manualInput='true' :touchUI='windowInnerWidth < 576' )
                                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_execucao = null;')
                                    .feedback--errors(v-if='submitted && $v.model.dt_execucao.$error')
                                        .form-message--error(v-if="!$v.model.dt_execucao.required") <b>Data da execução</b> é obrigatório.

                                .p-col-12
                                    Button(label='Validar')
</template>

<style lang="scss">
    .waitingForm-spinner {
        width: 60px;
        height: auto;
    }
    .waitinAction {
        width: 30px;
        height: auto;
    }
    .waitingImprimirGuia {
        width: 29px;
        height: auto;
    }
    .waitingExecutar-spinner {
        width: 30px;
        height: auto;
    }
    .dialogExecutar {
        width: 96%;
        max-width: 400px;
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import ProgressSpinner from 'primevue/progressspinner'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Checkbox from 'primevue/checkbox'
    import Calendar from 'primevue/calendar'
    import Dialog from 'primevue/dialog'
    import Tooltip from 'primevue/tooltip'
    import { required } from 'vuelidate/lib/validators'
    import { GuiaEletronica, Agendamento, Caixas } from '../../middleware'
    import { pCalendarLocale_ptbr } from './../../utils'

    import { saveAs } from 'file-saver'
    import moment from 'moment'
    export default {
        components: { Calendar, Panel, InputText, Button, ProgressSpinner, DataTable, Column, Checkbox, Dialog, Tooltip },
        directives: { tooltip: Tooltip },
        created () {
            let usuario = JSON.parse(localStorage.getItem('usuario'))
            this.currentEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento]
        },
        data () {
            return {
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                max_date: moment()._d,
                ie_tem_integracao_shift: false,
                model: { id: null, hash: null, dt_execucao: moment()._d },
                results: [],
                estabelecimentos: {},
                guias: {},
                selected: [],
                selectedReenviar: [],
                guiasReenviar: {},
                allSelected: false,
                waitingForm: false,
                waitingExecutar: false,
                waitinAction: false,
                waitingImprimirGuia: 0,
                submitted: false,
                currentEstabelecimento: null,
                dialogExecutar: false
            }
        },
        validations () {
            let v = {
                model: {
                    id: { required },
                    hash: { required },
                    dt_execucao: { required }
                }
            }

            return v
        },
         watch: {
            "model.dt_execucao": function (val) {
                this.model.dt_execucao_f = moment(val).format('DD/MM/YYYY')
            },
        },
        methods: {
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            reset () {
                this.model = { id: null, hash: null, dt_execucao: moment()._d }
                this.results = []
                this.estabelecimentos = {}
                this.guias = {}
                this.selected = []
                this.allSelected = false
                this.submitted = false
            },
            onChangeSelected() {
                const guia = this.results[0]
                if (guia && guia.cd_guia_tipo == 4) {
                    if (this.allSelected) {
                        this.selected = []
                        this.allSelected = false
                    } else {
                        this.selected = this.results.map(x => x.cd_guia)
                        this.allSelected = true
                    }
                }
            },
            openDialogExecutar () {
                if (! this.selected.length) {
                    this.$toast.error('Selecione pelo menos 1 guia', { duration: 3000 })
                    return 0
                }
                this.dialogExecutar = true
            },
            executarGuias () {
                let cd_guias = [...this.selected]

                let dataSend = {
                    id: this.model.id, hash: this.model.hash, cd_estabelecimento: this.currentEstabelecimento.id, cd_guias,
                    dt_execucao: moment(this.model.dt_execucao, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }

                this.waitingExecutar = true
                GuiaEletronica.executarGuias(dataSend).then(response => {
                    this.waitingExecutar = false
                    let _s = cd_guias.length - 1 > 1 ? 's' : ''
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.success(`Guia${_s} executada${_s}`, { duration: 3000 })
                        
                        try{
                            let erros = response.data[response.data.length - 1].erros_import
                            if (erros.length > 0){
                                for (let i = 0;i < erros.length;i++){
                                    this.$toast.warning(erros[i], { duration: 10000 });
                                }
                            }else{
                                this.$toast.success('A importação do(s) procedimento(s) para a Shift está sendo processada.')
                                // this.$toast.success('O Código do pedido é: ' + response.data[response.data.length - 1].cd_pedido, { duration: 10000 })
                            }
                        }catch (e){
                            console.log(e)
                        }
                        this.results = []
                        this.estabelecimentos = {}
                        this.guias = {}
                        this.selected = []
                        this.dialogExecutar = false
                        this.handleSubmit()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                this.waitingForm = true
                GuiaEletronica.validarGuia(dataSend.id, dataSend.hash).then(response => {
                    if (([200, 201, 204]).includes(response.status)) {

                        this.model.dt_execucao_f = moment(this.model.dt_execucao, 'DD/MM/YYYY').format('DD/MM/YYYY')

                        response.data.forEach(guia => {
                            if (!this.guias[guia.cd_paciente.id]) this.guias[guia.cd_paciente.id] = {}

                            if (!this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.nm_fantasia])
                                this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.nm_fantasia] = guia

                            this.results.push({
                                cd_estabelecimento: guia.cd_estabelecimento.id,
                                nm_estabelecimento: guia.cd_estabelecimento.nm_fantasia,
                                cd_paciente: guia.cd_paciente.id,
                                nm_paciente: guia.cd_paciente.nm_pessoa_fisica,
                                cd_guia: guia.id,
                                cd_guia_procedimentos: guia.cd_guia_procedimentos,
                                ie_estorno_pendente: guia.ie_estorno_pendente,
                                cd_guia_situacao: guia.cd_guia_situacao,
                                cd_guia_tipo: guia.cd_guia_tipo,
                                nr_valortotal: guia.nr_valortotal,
                                nr_valortotal_f: this.formatPrice(guia.nr_valortotal),
                                nm_procedimento: guia.guiaeletronicaitem_set[0] ? guia.guiaeletronicaitem_set[0].nm_procedimento : '',
                                cd_horario_agendamento: guia.cd_horario_agendamento,
                                nm_usuario_execucao: guia.nm_usuario_execucao,
                                dt_execucao_f: moment(guia.dt_execucao).format('DD/MM/YYYY'),
                                cd_ordem_servico_shift: guia.guiaeletronicaitem_set[0] ? guia.guiaeletronicaitem_set[0].cd_ordem_servico_shift : ''
                            })
                            if (guia.cd_estabelecimento.cd_integracao_shift){
                                this.ie_tem_integracao_shift = true
                            }
                        })

                        GuiaEletronica.getAnexos(dataSend.id, dataSend.hash, { ie_tipo_anexo: 'PE' }).then(response => {
                            if (response.status === 200) {
                                this.results.forEach(result => {
                                    result.anexos = response.data.filter(anexo => anexo.cd_guia_eletronica === result.cd_guia)
                                })
                            }
                            this.waitingForm = false
                        })

                    } else if (response.data.detail) {
                        this.waitingForm = false
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 6000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            imprimirGuia (id) {
                this.waitingImprimirGuia = id
                Agendamento.imprimirGuia(id).then(response => {
                    this.waitingImprimirGuia = 0
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            saveAs(blob, `guia-atendimento-${ id }.pdf`)
                        }
                    }
                })
            },
            imprimirTodasGuias () {
                this.waitinAction = true
                return Caixas.imprimirGuiasLote(this.model.id).then(response => {
                    this.waitinAction = false
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            saveAs(blob, `guia-atendimento-${ this.model.id }.pdf`)
                        }
                    }
                    return 0
                })
            },
            reenviarPedidos(){
                let cd_guias = [...this.selectedReenviar]
                let dataSend = {
                    cd_transacao: this.model.id, 
                    hash: this.model.hash, 
                    cd_estabelecimento: this.currentEstabelecimento.id, 
                    cd_guias: cd_guias
                }
                this.waitingForm = true
                GuiaEletronica.reenviarExamesShift(dataSend).then(response => {
                    let _s = cd_guias.length - 1 > 1 ? 's' : ''
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.success(`Guia${_s} reenviadas com sucesso.${_s}`, { duration: 3000 })
                        try{
                            let erros = response.data[response.data.length - 1].erros_import
                            if (erros.length > 0){
                                for (let i = 0;i < erros.length;i++){
                                    this.$toast.warning(erros[i], { duration: 10000 });
                                }
                            }else{
                                this.$toast.success('A importação do(s) procedimento(s) para a Shift está sendo processada.')
                            }
                        }catch (e){
                            console.log(e)
                        }
                        this.results = []
                        this.estabelecimentos = {}
                        this.guias = {}
                        this.selected = []
                        this.dialogExecutar = false
                        this.handleSubmit()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                    this.waitingForm = false
                })

            }
        }
    }
</script>